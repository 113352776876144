import React from "react"
import { StaticLogo } from "../Hero.css"

const LongsongVideo = () => {
  return (
  
      <StaticLogo
        xmlns="http://www.w3.org/2000/svg"
        width="495.168"
        height="310.259"
        viewBox="0 0 495.168 310.259"
      >
        <path
          id="Path_10261"
          data-name="Path 10261"
          d="M29.294,214.391V143.912H45.832v56.383H66.038v14.1Z"
          transform="translate(-29.294 95.868)"
          fill="#fff"
        />
        <path
          id="Path_10262"
          data-name="Path 10262"
          d="M144.375,173.066l-.753-6.293h-.188c-3.379,6.014-7.14,7.891-14.282,7.891-14,0-18.513-9.677-18.513-22.46V123.261c0-15.317,7.049-22.462,22.18-22.462,14.094,0,22.455,6.014,22.455,20.394v3.813H138.735V122.6c0-6.012-.751-9.021-5.168-9.112-5.166-.093-6.389,2.724-6.389,7.326v34.87c0,3.947,1.786,6.3,6.11,6.3,5.635,0,5.824-4.512,5.824-9.019v-5.547h-5.919V134.724h22.46v38.342Z"
          transform="translate(73.978 41.133)"
          fill="#fff"
        />
        <path
          id="Path_10263"
          data-name="Path 10263"
          d="M74.41,131.315c-14.471,0-22.555,7.517-22.555,21.05v28.662c0,15.883,5.828,24.153,22.555,24.153s22.273-8.27,22.273-24.153V152.365c0-13.533-7.8-21.05-22.273-21.05m5.735,51.122c0,4.045-.377,10.056-5.735,10.056S68.4,186.481,68.4,182.437V153.5c0-4.324.658-9.5,5.919-9.5,5.454,0,5.83,5.172,5.83,9.5Z"
          transform="translate(-0.65 79.875)"
          fill="#fff"
        />
        <path
          id="Path_10264"
          data-name="Path 10264"
          d="M95.765,147.814l-.188.188,1.5,39.282H81.388V116.8H98.4l12.78,39.381h.281l-1.6-39.381h15.7v70.486H108.64Z"
          transform="translate(36.843 61.445)"
          fill="#fff"
        />
        <path
          id="Path_10265"
          data-name="Path 10265"
          d="M155.561,133.376v4.98c0,4.042,1.321,7.612,6.579,7.612,3.854,0,5.545-3.479,5.545-7.047,0-5.921-4.886-8.175-9.21-10.806-5.075-2.918-9.4-5.64-12.5-9.212-3.009-3.57-4.793-7.894-4.793-14.1,0-13.249,7.987-20.017,20.769-20.017,14.378,0,21.711,9.587,20.959,22.839H167.685c-.186-4.135,0-9.773-5.449-10.15-3.382-.281-5.354,1.6-5.733,4.7-.472,4.226,1.689,6.389,4.979,8.642,6.2,3.949,11.561,6.391,15.41,9.963S183,129.053,183,137.6c0,13.531-7.519,21.052-21.239,21.052-15.131,0-21.522-8.456-21.522-17.761v-7.519h15.317Z"
          transform="translate(111.564 20.805)"
          fill="#fff"
        />
        <path
          id="Path_10266"
          data-name="Path 10266"
          d="M191.422,68.782c-14.473,0-22.557,7.517-22.557,21.052V118.5c0,15.882,5.826,24.151,22.557,24.151s22.269-8.268,22.269-24.151V89.835c0-13.536-7.8-21.052-22.269-21.052m5.731,51.122c0,4.042-.379,10.059-5.731,10.059S185.4,123.946,185.4,119.9V90.963c0-4.324.658-9.494,5.919-9.494,5.451,0,5.831,5.17,5.831,9.494Z"
          transform="translate(147.9 0.486)"
          fill="#fff"
        />
        <path
          id="Path_10267"
          data-name="Path 10267"
          d="M212.777,85.278l-.191.188,1.507,39.284H198.4V54.267h17.013l12.78,39.377h.284l-1.6-39.377h15.692v70.484H225.65Z"
          transform="translate(185.391 -17.942)"
          fill="#fff"
        />
        <path
          id="Path_10268"
          data-name="Path 10268"
          d="M261.377,110.529l-.754-6.3h-.188c-3.379,6.017-7.14,7.9-14.282,7.9-14,0-18.515-9.68-18.515-22.462V60.724c0-15.32,7.049-22.462,22.183-22.462,14.094,0,22.455,6.012,22.455,20.394v3.811H255.737V60.061c0-6.01-.749-9.021-5.17-9.112-5.166-.093-6.387,2.724-6.387,7.328V93.144c0,3.947,1.786,6.3,6.11,6.3,5.635,0,5.824-4.509,5.824-9.019V84.874h-5.919V72.187h22.457v38.342H261.377Z"
          transform="translate(222.516 -38.262)"
          fill="#fff"
        />
      </StaticLogo>

  )
}

export default LongsongVideo
